import React from "react"

import { Link } from "gatsby"

const Nav = () => (
    <header className="flex flex-col gap-3 pb-4 mb-16">
        <h1 className="my-0">
            <Link to="/" className="hover:text-black focus:text-black">rgenchev</Link>
        </h1>

        <div className="flex flex-row">
            <Link to="/about" className="mr-1 focus:text-black font-bold" activeClassName="hover:text-black text-black no-underline">about</Link>•
            <Link to="/work" className="mr-1 ml-1 font-bold" activeClassName="hover:text-black text-black no-underline">work</Link>•
            <Link to="/blog" className="mr-1 ml-1 font-bold" activeClassName="hover:text-black text-black no-underline">blog</Link>•
            <Link to="/photos" className="ml-1 font-bold" activeClassName="hover:text-black text-black no-underline">photos</Link>
        </div>
    </header>
)

export default Nav
