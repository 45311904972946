import * as React from "react"

import Nav from "./nav";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <div className="m-0 m-auto max-w-xl pt-6 px-5 flex flex-col h-screen">
      <Nav />

      <main className="flex-1">{children}</main>

      <Footer />
    </div>
  )
}

export default Layout
